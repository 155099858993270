import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "./layouts/LoginLayout/inedx";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/mint",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Mint/MintScreen")),
  },
  {
    exact: true,
    path: "/shop",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/LearnMore/Shope")),
  },
  {
    exact: true,
    path: "/wallet",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wallet/Wallet")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Static/About")),
  },
  {
    exact: true,
    path: "/media",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Static/Media")),
  },
  {
    exact: true,
    path: "/race-time",
    // layout: HomeLayout,
    component: lazy(() => import("src/component/RaceTime")),
  },
  // {
  //   exact: true,
  //   path: "/race",
  //   // layout: HomeLayout,
  //   component: lazy(() => import("src/component/RaceTime")),
  // },
  // {
  //   exact: true,
  //   path: "/race-time",
  //   element: lazy(() => import("src/component/RaceTime")),
  // },
  {
    exact: true,
    path: "/privacy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Static/Privacy")),
  },

  {
    exact: true,
    path: "/env",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/EnvironmentHover")),
  },
  {
    exact: true,
    path: "/terms",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Static/Terms")),
  },
  {
    exact: true,
    path: "/live-and-upcomming",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Events/LiveEvents")),
  },
  {
    exact: true,
    path: "/practice-race",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/PracticeRace/PracticeRace")),
  },
  {
    exact: true,
    path: "/daze-card-collection",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Events/DazeCardCollection")),
  },
  {
    exact: true,
    path: "/vehical-character",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Component/SelecVehicalCharacter")
    ),
  },
  {
    exact: true,
    path: "/game-lobby",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Component/GameLobby")),
  },
  // {
  //   exact: true,
  //   path: "/car-selection",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Component/SelecctCarSection/CarSelection")
  //   ),
  // },
  {
    exact: true,
    path: "/character-selection",
    layout: HomeLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Component/SelectCharacterSection/CharacterSelection"
      )
    ),
  },
  {
    exact: true,
    path: "/component-selection",
    layout: HomeLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Component/SelectComponentSection/ComponentDetails"
      )
    ),
  },
  {
    exact: true,
    path: "/component-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Component/ComponentDetail")),
  },
  {
    exact: true,
    path: "/mint-card",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Component/MintCard")),
  },

  {
    exact: true,
    path: "/select-environment",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Events/SelectEnv")),
  },
  {
    exact: true,
    path: "/leaderboard",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Leaderboard/Racingersult")),
  },
  {
    exact: true,
    path: "/leaderboard-result",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Leaderboard/LeaderboardTable/LeaderboardData")
    ),
  },
  {
    exact: true,
    path: "/improvement",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/LearnMore/Improvement")),
  },
  {
    exact: true,
    path: "/setting",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Admin/Setting/Profile")),
  },
  {
    exact: true,
    path: "/notifications",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Admin/Static/Notifications")),
  },
  {
    exact: true,
    path: "/profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/ProfileUser")),
  },
  {
    exact: true,
    path: "/learn-more",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/LearnMore/HorizontalLinearStepper")
    ),
  },
  {
    exact: true,
    path: "/nft-detail",
    layout: HomeLayout,
    component: lazy(() => import("src/component/NftdetailSection")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forgot")),
  },
  {
    exact: true,
    path: "/reset",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Reset")),
  },

  //DashBoard admin routes
  {
    exact: true,
    adminAuth: true,
    path: "/dashborad",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/UpcomingRace")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/user-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/ProfileUser")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/Setting/Changepassword")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/Dashboard/DashboardHome")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/usermanagemant",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/UserManagement/UserManagment")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/static",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/Static/index")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/view-content",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/Static/ViewStatic")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/edit-content",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/Static/EditContent")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/Static/Notification")),
  },

  {
    exact: true,
    adminAuth: true,
    path: "/sub-admin-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/SubAdminManagement/index")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/ip-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/IpManagement/ListIpManagement")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/add-sub-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/SubAdminManagement/AddNewSubAdmin")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/view-sub-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/SubAdminManagement/AddNewSubAdmin")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/user-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/UserManagement/UserDetails")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/confirmation-dialog",
    layout: DashboardLayout,
    component: lazy(() => import("src/component/ConfirmationDialogBox")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/rewards-dialog",
    layout: DashboardLayout,
    component: lazy(() => import("src/component/RewardsDialogBox")),
  },

  {
    exact: true,
    adminAuth: true,
    path: "/userlisted-nftdetails",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/UserManagement/UserListedNftDetails")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/listednft-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/NftManagement/ListedNftDetails")
    ),
  },

  {
    exact: true,
    adminAuth: true,
    path: "/userlisted-nft",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/UserManagement/UserListedNFTs")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/nft-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/NftManagement/NftManagement")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/transaction-managemet",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/TransactionManagment/TransactionManagement")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/withdraw-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/WithdrawManagement")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/event-managemet",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/EventManagement/EventManagement")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/garage-managemet",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/GarageManagement/GarageManagement")
    ),
  },

  {
    exact: true,
    adminAuth: true,
    path: "/add-event",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/EventManagement/AddEvent")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/add-garage-newitem",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/GarageManagement/AddGarageNewItem")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/announcement-detail",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Announcement/AnnouncDetail")),
  },

  {
    exact: true,
    adminAuth: true,
    path: "/view-all-announcement",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Announcement/Allanncouncet")),
  },

  {
    exact: true,
    adminAuth: true,
    path: "/announcement-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Admin/AnnouncementManagement/AnnouncementManagement"
      )
    ),
  },

  {
    exact: true,
    adminAuth: true,
    path: "/add-announcement",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/AnnouncementManagement/AddAnnouncement")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/edit-announcement",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/AnnouncementManagement/AddAnnouncement")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/advertisement-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/AdvertisementManagement/AdvertiseMgmt")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/add-advertisement",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/AdvertisementManagement/AddAdvertisement")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/edit-advertisement",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/AdvertisementManagement/AddAdvertisement")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
